import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Combobox, Select } from 'hds-react';
import PlaygroundPreview from '../../../components/Playground';
import TabsLayout from './tabs.mdx';
import InternalLink from '../../../components/InternalLink';
export const _frontmatter = {
  "slug": "/components/dropdown",
  "title": "Dropdown",
  "navTitle": "Dropdown"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <h3 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}>{`Example`}<a parentName="h3" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <div style={{
        maxWidth: '400px'
      }}>
    <Select required label="Select" helper="Assistive text" placeholder="Placeholder" options={[{
          label: 'Americium'
        }, {
          label: 'Berkelium'
        }, {
          label: 'Californium'
        }, {
          label: 'Copernicium'
        }]} mdxType="Select" />
    <Combobox multiselect required label="Combobox" helper="Assistive text" placeholder="Placeholder" options={[{
          label: 'Americium'
        }, {
          label: 'Berkelium'
        }, {
          label: 'Californium'
        }, {
          label: 'Copernicium'
        }, {
          label: 'Einsteinium'
        }, {
          label: 'Fermium'
        }, {
          label: 'Mendelevium'
        }, {
          label: 'Plutonium'
        }]} clearButtonAriaLabel="Clear all selections" selectedItemRemoveButtonAriaLabel="Remove ${value}" toggleButtonAriaLabel="Toggle menu" style={{
          marginTop: 'var(--spacing-s)'
        }} mdxType="Combobox" />
  </div>
    </PlaygroundPreview>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`A label should always be provided with dropdowns.`}</strong>{` Aim for labels that are short, concise and easy to understand.`}</li>
      <li parentName="ul">{`Dropdowns usually have four (4) or more options. When having only 2-3 options, it is usually better to use `}<InternalLink href="/components/radio-button" mdxType="InternalLink">{`radio buttons`}</InternalLink>{` or `}<InternalLink href="/components/checkbox" mdxType="InternalLink">{`checkboxes`}</InternalLink>{`. Also, if options need to be easily comparable (for example, product pricing), prefer radio buttons over dropdown.`}</li>
      <li parentName="ul">{`It is recommended to set a default option for a single selection dropdown. If the default option is also the recommended option, you can mark the option with a text "`}<em parentName="li">{`(recommended)`}</em>{`".`}</li>
      <li parentName="ul">{`If your dropdown has 8 or more options, consider using the `}<a parentName="li" {...{
          "href": "#combobox-single-selection"
        }}>{`Combobox`}</a>{` variant which offers filtering options by typing.`}</li>
      <li parentName="ul">{`Try to avoid dropdown options that span over one line. Aim for short texts for all options.`}</li>
      <li parentName="ul">{`If possible, do input validation client-side in real-time and provide the user with immediate feedback after selection.`}</li>
    </ul>
    <h4 {...{
      "id": "should-i-use-select-or-combobox",
      "style": {
        "position": "relative"
      }
    }}>{`Should I use Select or Combobox?`}<a parentName="h4" {...{
        "href": "#should-i-use-select-or-combobox",
        "aria-label": "should i use select or combobox permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use Select variant when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`there are less than 8 options`}</li>
          <li parentName="ul">{`the user does not need to be able to filter options by typing`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Use Combobox variant when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`there are 8 or more options`}</li>
          <li parentName="ul">{`the user needs to be able to filter options by typing`}</li>
        </ul>
      </li>
    </ul>
    <h4 {...{
      "id": "icon-or-no-icon",
      "style": {
        "position": "relative"
      }
    }}>{`Icon or no icon?`}<a parentName="h4" {...{
        "href": "#icon-or-no-icon",
        "aria-label": "icon or no icon permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use an icon when`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`there is a clear added value to using an icon`}</li>
          <li parentName="ul">{`when the icon is not just for illustrative purposes`}</li>
          <li parentName="ul">{`when you can add an icon to all select and combobox elements in the formgroup to maintain consistency`}</li>
        </ul>
      </li>
    </ul>
    <h3 {...{
      "id": "variations",
      "style": {
        "position": "relative"
      }
    }}>{`Variations`}<a parentName="h3" {...{
        "href": "#variations",
        "aria-label": "variations permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "select-single-selection",
      "style": {
        "position": "relative"
      }
    }}>{`Select (single selection)`}<a parentName="h4" {...{
        "href": "#select-single-selection",
        "aria-label": "select single selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Select dropdown serves in most use cases when the user needs to select one of 4 to 7 options. If there are 8 or more options available, consider using `}<a parentName="p" {...{
        "href": "#combobox-single-selection"
      }}>{`Combobox`}</a>{` to allow filtering.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Select required label="Label" helper="Assistive text" placeholder="Placeholder" options={[{
        label: 'Americium'
      }, {
        label: 'Berkelium'
      }, {
        label: 'Californium'
      }, {
        label: 'Copernicium'
      }]} style={{
        maxWidth: '400px'
      }} mdxType="Select" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "select-multi-selection",
      "style": {
        "position": "relative"
      }
    }}>{`Select (multi-selection)`}<a parentName="h4" {...{
        "href": "#select-multi-selection",
        "aria-label": "select multi selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Select multi-selection variant allows the user to select one or more options simultaneously. Note! Select dropdown does not feature filtering options by typing. When this feature is needed or there are a large number of options available, consider using consider using `}<a parentName="p" {...{
        "href": "#combobox-multi-selection"
      }}>{`Combobox`}</a>{`.`}</p>
    <p>{`Multi-select also supports icon if needed.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Select multiselect required label="Label" placeholder="Placeholder" helper="Assistive text" options={[{
        label: 'Plutonium'
      }, {
        label: 'Americium'
      }, {
        label: 'Copernicium'
      }, {
        label: 'Berkelium'
      }]} clearButtonAriaLabel="Clear all selections" selectedItemRemoveButtonAriaLabel="Remove ${value}" style={{
        maxWidth: '400px'
      }} mdxType="Select" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "combobox-single-selection",
      "style": {
        "position": "relative"
      }
    }}>{`Combobox (single selection)`}<a parentName="h4" {...{
        "href": "#combobox-single-selection",
        "aria-label": "combobox single selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Combobox dropdown serves in most use cases when the user needs to select one from 8 or more options (up to hundreds of options). Combobox variant features filtering options by typing.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Combobox required label="Label" helper="Assistive text" placeholder="Placeholder" toggleButtonAriaLabel="Toggle menu" options={[{
        label: 'Americium'
      }, {
        label: 'Berkelium'
      }, {
        label: 'Californium'
      }, {
        label: 'Copernicium'
      }, {
        label: 'Einsteinium'
      }, {
        label: 'Fermium'
      }, {
        label: 'Mendelevium'
      }, {
        label: 'Plutonium'
      }]} style={{
        maxWidth: '400px'
      }} mdxType="Combobox" />
    </PlaygroundPreview>
    <h4 {...{
      "id": "combobox-multi-selection",
      "style": {
        "position": "relative"
      }
    }}>{`Combobox (multi-selection)`}<a parentName="h4" {...{
        "href": "#combobox-multi-selection",
        "aria-label": "combobox multi selection permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Combobox multi-select variant allows the user to select one or more options simultaneously. Combobox variant features filtering options by typing.`}</p>
    <p>{`Multi-select also supports icon if needed.`}</p>
    <PlaygroundPreview mdxType="PlaygroundPreview">
  <Combobox multiselect required label="Label" helper="Assistive text" placeholder="Placeholder" options={[{
        label: 'Americium'
      }, {
        label: 'Berkelium'
      }, {
        label: 'Californium'
      }, {
        label: 'Copernicium'
      }, {
        label: 'Einsteinium'
      }, {
        label: 'Fermium'
      }, {
        label: 'Mendelevium'
      }, {
        label: 'Plutonium'
      }]} clearButtonAriaLabel="Clear all selections" selectedItemRemoveButtonAriaLabel="Remove ${value}" toggleButtonAriaLabel="Toggle menu" style={{
        maxWidth: '400px'
      }} mdxType="Combobox" />
    </PlaygroundPreview>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      